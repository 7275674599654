import Vue from 'vue'
import Component from 'vue-class-component'
import breakpoints from '@/plugins/breakpoints'

@Component
export class BreakpointsMixin extends Vue {
  breakpoints = breakpoints

  get isSmallMobile(): boolean {
    return breakpoints.width <= 450
  }

  get isMobile(): boolean {
    return breakpoints.width <= 640
  }

  get isTablet(): boolean {
    return breakpoints.width < 1024
  }
}
