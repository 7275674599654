import axios from '@/api/axios'
import {
  InvoiceModel,
  DepositInvoiceModel,
  PaymentResponseModel,
} from '@/core/models/InvoicesModel'

class InvoicesService {
  async getInvoices(): Promise<InvoiceModel[]> {
    try {
      const response = await axios.get('api/b2b/v1/invoices')
      return response.data.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('getInvoices', error.message, error.response?.data)
      }
      throw error
    }
  }

  async downloadInvoice(id: number): Promise<Blob> {
    try {
      const response = await axios.get(`api/b2b/v1/invoices/${id}`, {
        responseType: 'blob',
      })
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('downloadInvoice', error.message, error.response?.data)
      }
      throw error
    }
  }

  async payInvoice(id: number): Promise<PaymentResponseModel> {
    try {
      const response = await axios.post(`api/b2b/v1/invoices/${id}/pay`)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('payInvoice', error.message, error.response?.data)
      }
      throw error
    }
  }

  async updatePayment(uid: string): Promise<any> {
    try {
      const response = await axios.post(`api/b2b/v1/invoices/refresh/${uid}`)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('updatePayment', error.message, error.response?.data)
      }
      throw error
    }
  }

  async deposit(invoice: DepositInvoiceModel): Promise<PaymentResponseModel> {
    try {
      const response = await axios.post('/api/b2b/v1/invoices/deposit', invoice)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log('deposit', error.message, error.response?.data)
      }
      throw error
    }
  }

  isServiceError = axios.isAxiosError
}

export default new InvoicesService()
