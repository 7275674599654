export const toLocalCurrency = (
  val: number,
  isChange = false,
  withPenny = true
): string => {
  const norm = Number(val.toFixed(2))
  const sign = norm < 0 ? '-\xa0' : norm > 0 && isChange ? '+\xa0' : ''
  const unsign = norm < 0 ? -norm : norm
  return (
    sign +
    unsign
      .toLocaleString('ru-RU', {
        minimumFractionDigits: withPenny ? 2 : 0,
        maximumFractionDigits: withPenny ? 2 : 0,
      })
      .replace(',', '.')
  )
}
